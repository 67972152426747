
import React from 'react';
import EditPatientProviderDialog from './EditPatientProviderDialog'
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';


import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    CircularProgress
} from '@material-ui/core/';

import EnhancedTableToolbar from '@apricityhealth/web-common-lib/components/EnhancedTableToolbar';
import { deletePatientProvider, getPatientProviders } from '@apricityhealth/web-common-lib/utils/Services';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

class PatientProvider extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            patientId: props.patientId,
            providers: [],
            progress: null,
            dialog: null,
            error: null
        }
    }

    componentDidMount(){
        this.getPatientProviders()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.patientId !== this.props.patientId){
            this.setState({ providers: [], patientId: this.props.patientId}, this.getPatientProviders.bind(this));
        }
    }

    getPatientProviders() {
        this.setState({progress: <CircularProgress />});
        getPatientProviders(this.props.appContext, this.state.patientId).then((result) => {
            this.setState({providers: result, progress: null});
        }).catch((err) => {
            this.setState({progress: null, error: getErrorMessage(err)});
        })
    }

    deleteProvider(e, i) {
        const { patientId, providers } = this.state;

        e.stopPropagation();
        e.preventDefault();

        this.setState({progress: <CircularProgress />, error: null});
        deletePatientProvider(this.props.appContext, patientId, providers[i].provider.providerId).then((result) => {
            this.setState({progress: null}, this.getPatientProviders.bind(this));
        }).catch((err) => {
            this.setState({progress: null, error: getErrorMessage(err)});
        })
    }

    onAdd() {
        this.setState({dialog: <EditPatientProviderDialog appContext={this.props.appContext} patientId={this.state.patientId} 
            onCancel={() => this.setState({dialog: null})}
            onDone={() => this.setState({dialog: null}, this.getPatientProviders.bind(this))} />});
    }

    render(){
        const self = this;
        const { providers, dialog, progress, error } = self.state;

        let providerRows = () => {
            let tableRows = []
            if (Array.isArray(providers)){
                providers.forEach( (providerItem, index)=> {
                    if (providerItem.provider){
                        let email = ''
                        if (providerItem.provider.email && providerItem.provider.emails.length){
                            providerItem.provider.emails.forEach((emailItem)=>{
                                email = email + emailItem.email+' '
                            })
                        }
                            email = providerItem.provider.emails[0].email
                        let row = (
                            <TableRow hover tabIndex={index} key={index}>
                                <TableCell>
                                    {providerItem.provider.firstName} {providerItem.provider.lastName}
                                </TableCell>
                                <TableCell>
                                    {providerItem.provider.providerId} 
                                </TableCell>
                                <TableCell>
                                    {email} 
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        aria-label="Delete"  
                                        onClick={(e) => self.deleteProvider(e, index)} >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )
                        tableRows.push(row)
                    }
                })
            }
            return tableRows 
        }
        return (
            <div>
                <Paper>
                <EnhancedTableToolbar
                    title='Patient Providers'
                    onAdd={this.onAdd.bind(this)}
                />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Provider </TableCell>
                            <TableCell>Provider ID </TableCell>
                            <TableCell>Provider Emails</TableCell>
                            <TableCell>Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {providerRows()}
                    </TableBody>
                </Table>
                {dialog}
                {progress}
                <span style={{color: 'red'}}>{error}</span>
                </Paper>
            </div>
        )
    }
}

export default PatientProvider;