import React from 'react';
import {
    FormControl,
    InputLabel,
    TextField,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Paper,
    Divider
} from '@material-ui/core/';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// import SelectDataType from '@apricityhealth/web-common-lib/components/SelectDataType';
// import SelectTupleIndex from '@apricityhealth/web-common-lib/components/SelectTupleIndex';
import MultiSelectOrg from '@apricityhealth/web-common-lib/components/MultiSelectOrg';
import SelectTrial from '@apricityhealth/web-common-lib/components/SelectTrial';
import SelectModelType from '@apricityhealth/web-common-lib/components/SelectModelType';
import MultiSelectPlan from '@apricityhealth/web-common-lib/components/MultiSelectPlan';
import SelectPatient from '@apricityhealth/web-common-lib/components/SelectPatient';
import SelectProvider from '@apricityhealth/web-common-lib/components/SelectProvider';
import { MuiPickersUtilsProvider, TimePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { isArrayValid } from '@apricityhealth/web-common-lib/utils/Services';
import Logger from '@apricityhealth/web-common-lib/utils/Logger';
import ReactJson from 'react-json-view';

const log = new Logger();

const TIMEZONE = (Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTZ');
const TIMEZONES = Object.freeze(
    [
        <MenuItem button key='UTZ' value='UTZ'>UTZ</MenuItem>,
        <MenuItem button key='America/Los_Angeles' value='America/Los_Angeles'>America/Los_Angeles</MenuItem>,
        <MenuItem button key='America/Denver' value='America/Denver'>America/Denver</MenuItem>,
        <MenuItem button key='America/Chicago' value='America/Chicago'>America/Chicago</MenuItem>,
        <MenuItem button key='America/New_York' value='America/New_York'>America/New_York</MenuItem>
    ]);

const ARG_TYPES = {
    "string": (appContext, self, arg) => {
        return <TextField key={arg.name} style={{ margin: 5, width: 600 }}
            value={arg.value}
            label={arg.description}
            onChange={(e) => {
                arg.value = e.target.value;
                self.onChange(arg);
            }} />
    },
    "stringarray": (appContex, self, arg) => {
        let { value } = arg;
        if (Array.isArray(value)) {
            value = value.join(',')
        }
        return <TextField key={arg.name} style={{ margin: 5, width: 600 }}
            value={value}
            label={arg.description}
            onChange={(e) => {
                arg.value = e.target.value.split(',');
                self.onChange(arg);
            }} />
    },
    "number": (appContext, self, arg) => {
        return <TextField key={arg.name} style={{ margin: 5, width: 600 }}
            value={arg.value}
            label={arg.description}
            type='number'
            onChange={(e) => {
                arg.value = e.target.value;
                self.onChange(arg);
            }} />
    },
    "boolean": (appContex, self, arg) => {
        return <FormControlLabel key={arg.name} label={arg.description} control={
            <Checkbox checked={arg.value} onChange={(e, v) => {
                arg.value = v;
                self.onChange(arg);
            }} />} />
    },
    "orgid": (appContext, self, arg) => {
        if (!arg.value) arg.value = "*";
        return <MultiSelectOrg
            label={arg.description}
            appContext={appContext}
            selectedValue={arg.value}
            enableNone={!arg.required}
            enableAll={arg.enableAll}
            onChange={(org) => { arg.value = org.orgId; self.onChange(arg) }}
            _margin={5}
        />
    },
    "trialid": (appContext, self, arg) => {
        return <SelectTrial
            key={arg.name}
            label={arg.description}
            style={{ margin: 5, width: 600 }}
            appContext={appContext}
            selectedValue={arg.value}
            enableNone={true}
            onChange={(trialId) => { arg.value = trialId; self.onChange(arg) }}
        />
    },
    "modelid": (appContext, self, arg) => {
        let planArg = self.props.args.find(a => a.name === "planId"); //planId may be an args thats being set. Use it.
        if (planArg && planArg.value === "") planArg.value = "*"; //default value is a space and that causes issues in the select model type component
        appContext.state.plan = { planId: planArg ? planArg.value : "*" }
        let category = self.props.args.find(a => a.name === "category"); //this is to filter the model types by category
        if (category) category = category.value;
        return <SelectModelType
            style={{ margin: 5, width: 600 }}
            category={category}
            appContext={appContext}
            modelId={arg.value}
            onChange={(model) => { if (model) { arg.value = model.modelId; self.onChange(arg) } }}
        />
    },
    "datetime": (appContext, self, arg) => {
        return <MuiPickersUtilsProvider key={arg.name} utils={DateFnsUtils}>
            <InputLabel style={{ fontSize: 12, marginLeft: 5, width: 600 }}>{arg.description}:</InputLabel>
            <KeyboardDateTimePicker
                margin="normal"
                style={{ width: 200, margin: 5 }}
                ampm={true}
                value={arg.value}
                onChange={(time) => { arg.value = time; self.onChange(arg) }}
            />
        </MuiPickersUtilsProvider>;
    },
    "starttime": (appContext, self, arg) => {
        if (!arg.value) arg.value = null;
        return <MuiPickersUtilsProvider key={arg.name} utils={DateFnsUtils}>
            <InputLabel style={{ fontSize: 12, marginLeft: 5, width: 600 }}>{arg.description}</InputLabel>
            <KeyboardDateTimePicker
                clearable
                margin="normal"
                style={{ width: 200, margin: 5 }}
                ampm={true}
                value={arg.value}
                onChange={(time) => {
                    arg.value = time;
                    self.onChange(arg)
                }}
            />
        </MuiPickersUtilsProvider>;
    },
    "endtime": (appContext, self, arg) => {
        if (!arg.value) arg.value = null;
        return <MuiPickersUtilsProvider key={arg.name} utils={DateFnsUtils}>
            <InputLabel style={{ fontSize: 12, marginLeft: 5, width: 600 }}>{arg.description}:</InputLabel>
            <KeyboardDateTimePicker
                clearable
                margin="normal"
                style={{ width: 200, margin: 5 }}
                ampm={true}
                value={arg.value}
                onChange={(time) => { arg.value = time; self.onChange(arg) }}
            />
        </MuiPickersUtilsProvider>;
    },
    "endhourminute": (appContext, self, arg) => {
        return <MuiPickersUtilsProvider key={arg.name} utils={DateFnsUtils}>
            <InputLabel style={{ fontSize: 12, marginLeft: 5, width: 600 }}>{arg.description}:</InputLabel>
            <TimePicker
                margin="normal"
                style={{ width: 100, margin: 5 }}
                ampm={true}
                value={arg.value}
                onChange={(time) => { arg.value = time; self.onChange(arg) }}
            />
        </MuiPickersUtilsProvider>;
    },
    "beginhourminute": (appContext, self, arg) => {
        return <MuiPickersUtilsProvider key={arg.name} utils={DateFnsUtils}>
            <InputLabel style={{ fontSize: 12, marginLeft: 5, width: 600 }}>{arg.description}:</InputLabel>
            <TimePicker
                margin="normal"
                style={{ width: 600, margin: 5 }}
                ampm={true}
                value={arg.value}
                onChange={(time) => { arg.value = time; self.onChange(arg) }}
            />
        </MuiPickersUtilsProvider>;
    },
    "patientid": (appContext, self, arg) => {
        return <SelectPatient key={arg.name}
            style={{margin: 5, width: 600}}
            label={arg.description}
            appContext={appContext}
            patientId={arg.value}
            enableNone={!arg.required}
            enableAll={arg.enableAll}
            onChange={(patientId) => { arg.value = patientId; self.onChange(arg) }}
        />;
    },
    "providerid": (appContext, self, arg) => {
        return <SelectProvider key={arg.name}
            style={{margin: 5, width: 600}}
            label={arg.description}
            appContext={appContext}
            providerId={arg.value}
            enableNone={!arg.required}
            enableAll={arg.enableAll}
            onChange={(providerId) => { arg.value = providerId; self.onChange(arg) }}
        />;
    },
    "planid": (appContext, self, arg) => {
        if (!arg.value) arg.value = "";
        return <MultiSelectPlan
            style={{margin: 5, width: 600}}
            key={arg.name}
            label={arg.description}
            appContext={appContext}
            value={arg.value}
            enableAll={arg.enableAll}
            onChange={(planIds) => { arg.value = planIds.join(','); self.onChange(arg) }}
            _margin={5}
        />
    },
    "intervaltype": (appContext, self, arg) => {
        if (!arg.value) arg.value = 1;
        return <FormControl style={{ margin: 5 }} key={arg.name} >
            <InputLabel style={{ width: 600 }}>{arg.description}:</InputLabel>
            <Select
                value={arg.value}
                style={{ width: 100, marginTop: 20 }}
                onChange={(event) => { arg.value = event.target.value; self.onChange(arg) }}
            >
                <MenuItem value=''>None</MenuItem>
                <MenuItem value='day'>Day</MenuItem>
                <MenuItem value='week'>Week</MenuItem>
                <MenuItem value='month'>Month</MenuItem>
                <MenuItem value='year'>Year</MenuItem>
            </Select>
        </FormControl>
    },
    "timezone": (appContext, self, arg) => {
        if (!arg.value) arg.value = TIMEZONE;
        return <FormControl style={{ margin: 5 }} key={arg.name} >
            <InputLabel style={{ width: 600 }}>{arg.description}:</InputLabel>
            <Select
                value={arg.value}
                style={{ width: 200, marginTop: 20 }}
                onChange={(event) => { arg.value = event.target.value; self.onChange(arg) }}
            >
                {TIMEZONES}
            </Select>
        </FormControl>
    },
    "json": (appContext, self, arg) => {
        if (arg.value === undefined) arg.value = '[]';

        let object = [];
        try {
            object = JSON.parse(arg.value);
        } catch( err ) {
            log.error('Failed to parse json:', err );
        }
        return <div>
            <br />
            <Typography>{arg.description}</Typography>
            <Divider />
            <Paper key={arg.name} style={{margin: 5}}>
            <ReactJson src={object} name={'json'}
            collapsed={3} 
            collapseStringsAfterLength={64} 
            displayDataTypes={false} 
            onEdit={(e) => {
                log.info("onEdit:", e );
                function castStringsToNumbers(cast) {
                    if (Array.isArray(cast) || typeof cast === 'object') {
                        for(let k in cast) {
                            cast[k] = castStringsToNumbers(cast[k])
                        }
                    }
                    if (typeof cast === 'string' && !isNaN(Number(cast)) ) {
                        cast = Number(cast);
                    }
                    return cast;
                }
                arg.value = JSON.stringify( castStringsToNumbers(e.updated_src) );
                self.onChange(arg) 
                return true;
            }}
            onAdd={(e) => {
                log.info("onAdd:", e );
                if (Array.isArray(e.new_value)) {
                    if (e.new_value.find((k) => typeof(k) === 'object')) {
                        // array of objects, so convert any null into an object
                        for(let k in e.new_value) {
                            if ( e.new_value[k] === null) {
                                e.new_value[k] = {};
                            }
                        }
                    }
                }
                arg.value = JSON.stringify(e.updated_src);
                self.onChange(arg) 
                return true;
            }}
            onDelete={(e) => {
                log.info("onDelete:", e );
                arg.value = JSON.stringify(e.updated_src);
                self.onChange(arg) 
                return true;
            }}
        /></Paper>
        </div>
    }
    // "dataid": (appContext, self, arg) => {
    //     return <SelectDataType appContext={appContext} value={arg.value} onChange={(dataType) => {
    //         console.log("SelectDataType:", dataType );
    //         arg.value = dataType.dataId;
    //         self.onChange(arg);
    //     }} />
    // },
    // "tupleindex": (appContext, self, arg) => {
    //     let dataIdArg = self.state.args.find((e) => e.type === 'dataId');
    //     if ( dataIdArg ) {
    //         return <SelectTupleIndex appContex={appContext} dataId={dataIdArg.value} tupleIndex={arg.value} onChange={(e) => {
    //             console.log("SelectTupleIndex:", e );
    //             arg.value = e;
    //             self.onChange(arg);
    //         }} />
    //     }
    // }
};

export class ReportParameters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            args: props.args,
            warning: null,
        };

    }

    componentDidUpdate(oldProps) {
        if (this.props.args !== oldProps.args)
            this.setState({ args: this.props.args });
    }


    onChange(newArg) {
        let { args } = this.state;
        let arg = args.find((item) => item.name === newArg.name);
        if (arg) {
            arg.value = newArg.value;
        }
        this.setState({ args });
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(args);
        }
    }


    render() {
        let { args, warning } = this.state;
        let { appContext } = this.props;
        let items = [];

        console.log("ReportParameters.render:", args);
        if (isArrayValid(args)) {
            for (let i = 0; i < args.length; ++i) {
                let arg = args[i];
                if (!arg.value && arg.default)
                    arg.value = arg.default;
                let parameter = ARG_TYPES[(arg.type || 'string').toLowerCase()];         // fall-back to the old method using the arg name
                if (! parameter ) {
                    log.warning(`arg type ${arg.type} not yet supported, defaulting to string.`);
                    parameter = ARG_TYPES['string'];
                }
                items.push(parameter(appContext, this, arg));
                items.push(<br key={i} />);
            }

        }
        return <Accordion style={{ margin: 5 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <b>Report Parameters</b>
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    <div style={styles.warning}>{warning}</div>
                    {items}
                </div>
            </AccordionDetails>
        </Accordion>;
    }
}

const styles = {
    org: {
        margin: 5,
        width: 300
    },
    warning: {
        color: "#f54b20"
    }
}

export default ReportParameters;